import { IS_RTC } from '@lib/constants';
import { DateRange } from '@mui/x-date-pickers-pro';
import endOfDay from 'date-fns/endOfDay';
import nextSaturday from 'date-fns/nextSaturday';
import nextSunday from 'date-fns/nextSunday';
import previousMonday from 'date-fns/previousMonday';
import startOfToday from 'date-fns/startOfToday';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslations } from 'next-intl';
import createStore from 'zustand';

export type EventEntryDateQuery = {
  eventDateStart: null | (string | null)[];
  eventDateEnd: null | (string | null)[];
};

export type EventIndexDateQueryState = {
  rangeKey: string;
  setRangeKey: (value: string | null) => void;
  rangeValue: DateRange<dayjs.Dayjs>;
  setRangeValue: (value: DateRange<dayjs.Dayjs>) => void;
};

export const useEventIndexDateQuery = createStore<EventIndexDateQueryState>((set) => ({
  rangeKey: 'custom',
  setRangeKey: (rangeKey) => set({ rangeKey: rangeKey ?? 'custom' }),
  rangeValue: [null, null],
  setRangeValue: (rangeValue) => set({ rangeValue }),
}));

export const getDefaultDateQuery = (): EventEntryDateQuery => {
  return {
    eventDateStart: null,
    eventDateEnd: IS_RTC ? [`>= ${dayjs(startOfToday()).format('YYYY-MM-DD')}`] : null,
    // eventDateEnd: null,
  };
};

export const selectDateQuery = ({ rangeValue }: EventIndexDateQueryState) => {
  const includeEventsWithoutDate = true;

  let dateQuery: EventEntryDateQuery = getDefaultDateQuery();

  const [selectionStartDate, selectionEndDate] = rangeValue;

  const selectedDate = selectionStartDate || selectionEndDate;

  if (!selectedDate) return { dateQuery };

  const startDate = selectionStartDate || selectedDate;

  let endDate = selectionEndDate || selectedDate;

  // if (endDate.isSame(startDate)) {
  endDate = endDate.add(1, 'day');
  // }

  const startStr = startDate.format();
  const endStr = endDate.format();

  if (!!startStr && !!endStr) {
    dateQuery = {
      eventDateStart: [`<= ${endStr}`],
      eventDateEnd: [`>= ${startStr}`],
    };
  }

  if (includeEventsWithoutDate) {
    if (dateQuery.eventDateEnd) dateQuery.eventDateEnd.push(null);
    if (dateQuery.eventDateStart) dateQuery.eventDateStart.push(null);
  }

  return {
    dateQuery,
  };
};

export const useDateRanges = () => {
  const t = useTranslations('time');
  const today = startOfToday();

  const ranges: Record<string, { label: string; value: DateRange<Dayjs> }> = {
    today: { label: t('today'), value: [dayjs(today), dayjs(endOfDay(today))] },
    week: {
      label: t('week'),
      value: [dayjs(previousMonday(today)), dayjs(endOfDay(nextSunday(today)))],
    },
    weekend: {
      label: t('weekend'),
      value: [dayjs(nextSaturday(today)), dayjs(endOfDay(nextSunday(today)))],
    },
  };

  return ranges;
};
